<template>
  <div>
    <h4 class="font-weight-bold pb-1 indigo--text">Gestión</h4>
    <h5 class="font-weight-light">Gestión de mandantes</h5>
    <v-form ref="formulario" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="12">
          <v-card class="elevation-3 mt-5">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Antecedentes Mandante
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Nombre"
                          v-model="datosFormulario.nombre"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_input"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Razón Social"
                          v-model="datosFormulario.razon_social"
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Rut"
                          v-model="datosFormulario.rut"
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Dirección"
                          v-model="datosFormulario.direccion"
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-row>
                        <v-col cols="12" lg="6">
                          <v-switch
                            v-model="datosFormulario.activo"
                            label="Estado"
                            color="primary"
                            hide-details
                            class="ml-5"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-col>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Contactos del mandante
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-data-table
                      :headers="headers"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :items="datosFormulario.contactos"
                      :no-results-text="noresult"
                      no-data-text="Sin resultados que mostrar"
                      :search="search"
                      :footer-props="{
                        'items-per-page-text': 'Resultados por página',
                        'items-per-page-all-text': 'Todos',
                      }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Contactos</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-dialog v-model="dialog" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                outlined
                                class="mb-2 indigo lighten-5 primary--text elevation-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                Nuevo Contacto
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row> </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.nombre"
                                        outlined
                                        hide-details
                                        label="Nombre"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.email"
                                        outlined
                                        hide-details
                                        label="Email"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.telefono"
                                        outlined
                                        hide-details
                                        label="Teléfono"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-switch
                                        v-model="editedItem.activo"
                                        label="Estado"
                                        color="primary"
                                        hide-details
                                        class="ml-5"
                                      ></v-switch>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                  Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                  Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                              <v-card-title class="headline"
                                >Estás seguro de eliminar este Contacto?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deleteItemConfirm"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.activo="{ item }">
                        <v-chip :color="getEstados(item.activo)" dark small>{{
                          getEstadosTexto(item.activo)
                        }}</v-chip>
                        <!--<v-icon :color="getEstados(item.activo)" dark outlined>
            mdi-{{ getEstadosTexto(item.activo) }}
          </v-icon>-->
                      </template>
                      <template v-slot:item.accion="{ item }">
                        <v-icon small class="mr-2 blue--text" @click="editItem(item)">
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          class="red--text text--darken-4"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider class="m-0"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="indigo lighten-5"
                class="mb-2 indigo lighten-5 primary--text"
                v-if="idDato"
                @click="editData"
                right
                >Editar</v-btn
              >
              <v-btn
                :loading="loading"
                color="indigo lighten-5"
                class="mb-2 indigo lighten-5 primary--text"
                v-if="!idDato"
                @click="saveData"
                right
                >Grabar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
class datosReporte {
  constructor(datos = {}) {
    this.nombre = datos.nombre;
    this.rut = datos.rut;
    this.razon_social = datos.razon_social;
    this.activo = datos.activo;
    this.direccion = datos.direccion;
    this.contactos = datos.contactos;
  }
}

export default {
  data() {
    return {
      valid: true,
      basic_validation_rules_input: [
        (v) => !!v || "Campo obligatorio",
        (v) => (v && v.length <= 50) || "Campo requerido",
      ],
      basic_validation_rules_email: [
        (v) => !!v || "E-mail es obligatorio",
        (v) => /.+@.+\..+/.test(v) || "Debe ingresar un Email válido",
      ],
      basic_validation_rules_select: [(v) => !!v || "Campo obligatorio"],
      basic_validation_rules_rut: [
        (v) => !!v || "Campo obligatorio",
        (v) => this.validaRut(v) || "Rut Incorrecto",
      ],

      loading: false,
      idDato: null,
      ex11: true,

      tipo: [
        {
          tipo: "ADMIN",
          text: "Admin",
        },
        {
          tipo: "USUARIO",
          text: "Usuario",
        },
      ],

      datosFormulario: new datosReporte(new datosReporte()),

      seleccionados: [],
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu3: false,
      noresult: "No existen resultados",
      search: "",
      sortBy: "ceco",
      sortDesc: false,
      headers: [
        {
          text: "Nombre Contacto",
          align: "start",
          value: "nombre",
        },
        {
          text: "Email",
          align: "start",
          value: "email",
        },
        {
          text: "Teléfono",
          align: "start",
          value: "telefono",
        },
        {
          text: "Estado",
          align: "start",
          value: "activo",
        },
        {
          text: "Accion",
          value: "accion",
          sortable: false,
        },
      ],
      editedIndex: -1,
      editedItem: {
        nombre: "",
        email: "",
        telefono: "",
        activo: true,
      },
      dialog: false,
      dialogDelete: false,
    };
  },
  methods: {
    validate() {
      this.$refs.formulario.validate();
    },
    validaRut: function (rutCompleto) {
      //rutCompleto = rutCompleto.replace("‐", "-");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";

      return this.dv(rut) == digv;
    },
    dv: function (T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    lg(tipo) {
      if (tipo == 1) {
        return this.usuarioDB.data.role == "ADMIN" ? 6 : 12;
      } else {
        return this.usuarioDB.data.role == "ADMIN" ? 12 : 6;
      }
    },

    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      if (this.idDato) {
        axios.get("/mandantes/mandante/" + this.idDato, config).then((response) => {
          console.log(response);
          this.datosFormulario = new datosReporte(response.data);
        });
      } else {
      }
    },

    async editData() {
      this.loading = true;

      if (this.$refs.formulario.validate()) {
        let config = {
          headers: {
            token: this.token,
          },
        };

        await axios
          .put("/mandantes/mandante/" + this.idDato, this.datosFormulario, config)
          .then((response) => {
            this.$router.push({
              name: "mandantes",
            });
            this.$toast.success(
              "Grabado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        this.loading = false;
        this.$toast.error(
          "Los campos en rojo son obligatorios, rellene y vuelva a intentarlo",
          "Error",
          this.notificationSystem.options.error
        );
      }
    },

    async saveData() {
      this.loading = true;

      if (this.$refs.formulario.validate()) {
        let config = {
          headers: {
            token: this.token,
          },
        };

        //console.log(this.datosFormulario);
        await axios
          .post("/mandantes/mandante/", this.datosFormulario, config)
          .then((response) => {
            setTimeout(() => (this.loading = false), 3000);
            this.$router.push({
              name: "mandantes",
            });
            this.$toast.success(
              "Grabado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        this.loading = false;
        this.$toast.error(
          "Los campos en rojo son obligatorios, rellene y vuelva a intentarlo",
          "Error",
          this.notificationSystem.options.error
        );
      }
    },
    getEstados(estado) {
      if (estado == true) return "green";
      else if (estado == false) return "red";
      else return "grey";
    },

    getEstadosTexto(estado) {
      if (estado == true) return "Activo";
      else if (estado == false) return "Inactivo";
      else return "N/A";
    },
    //Contactos
    editItem(item) {
      this.editedIndex = this.datosFormulario.contactos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datosFormulario.contactos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.datosFormulario.contactos.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        //Object.assign(this.desserts[this.editedIndex], this.editedItem);

        Object.assign(this.datosFormulario.contactos[this.editedIndex], this.editedItem);
      } else {
        this.datosFormulario.contactos.push(this.editedItem);
      }
      this.close();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.idDato = this.$route.params.id;
    } else {
      this.datosFormulario.contactos = [];
    }

    this.getData();
  },
  mounted() {},
  computed: {
    ...mapState(["token", "usuarioDB", "itemsMenu", "notificationSystem"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Contacto" : "Editar Contacto";
    },
  },
  watch: {
    seleccionados: function (val) {
      this.datosFormulario.mods = [];
      for (let nodos of val) {
        this.datosFormulario.mods.push(nodos.id);
      }
    },
  },
};
</script>
<style>
.v-label--active {
  color: #1c67fd !important;
  font-weight: bold;
}
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
</style>
